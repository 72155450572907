import './HomePage.css'
import home_header from './home_resources/header.webp'
import salcam from './home_resources/salcam.webp'
import mana_si_mur from './home_resources/mana_si_mur.webp'
import poliflora_de_primavara from './home_resources/poliflora_de_primavara.webp'
import poliflora_de_vara from './home_resources/poliflora_de_vara.webp'
import tei from './home_resources/tei.webp'
import poliflora from './home_resources/poliflora.webp'
import tinctura_propolis from './home_resources/tinctura_propolis.webp'
import alex_apicultor from './home_resources/alex_apicultor.webp'
import stupina from './home_resources/stupina.webp'

function HomePage() {
    return (
        <div className='home-page'>
            <div className='home-header'>
                <img
                    src={home_header}
                    alt="header" />
            </div>
            <div className='section-boxes' id="region-1">
                <div className='c-center products-listing'>
                    <div className='product-box'>
                        <div className='product-image'>
                            <img src={salcam} alt="miere de salcam" />
                        </div>
                        <h2 className='product-title'>Miere de salcâm</h2>
                        <label className='product-price'>40 RON / kg</label>
                        <p className='product-description'>
                            • excelent tonic
                            <br />• calmează tusea, având proprietăți antiseptice
                            <br />• înlătură astenia și nevrozele
                            <br />• ajută digestia, diminuând aciditatea gastrică
                            <br />• ajută la întărirea sistemului imunitar
                            <br />• ajută la reglarea colesterolului
                        </p>

                    </div>
                    <div className='product-box'>
                        <div className='product-image'>
                            <img src={mana_si_mur} alt="miere de mana si mur" />
                        </div>
                        <h2 className='product-title'>Miere de mană și mur</h2>
                        <label className='product-price'>40 RON / kg</label>
                        <p className='product-description'>
                            • provine din seva dulce de pe frunzele și scoarța arborilor, fiind extrem de asemănătoare din punct de vedere al proprietăților cu mierea de Manuka
                            <br />• are un conţinut de substanţe antibiotice foarte mare, fiind foarte apreciată în combaterea gripelor și răcelilor de sezon
                        </p>
                    </div>
                    <div className='product-box'>
                        <div className='product-image'>
                            <img src={poliflora_de_primavara} alt="miere poliflora" />
                        </div>
                        <h2 className='product-title'>Miere polifloră de primăvară</h2>
                        <label className='product-price'>35 RON / kg</label>
                        <p className='product-description'>
                            • este bogată în vitamine și minerale, datorită multitudinii de flori din care este culeasă, multe dintre ele fiind plante medicinale (păducel, măceș, păpădie, pomi fructiferi)
                            <br />• conferă energie, întărește sistemul imunitar, ameliorează tusea și iritațiile gâtului
                        </p>
                    </div>
                    <div className='product-box out-of-stock'>
                        <div className='product-image'>
                            <img src={poliflora_de_vara} alt="miere poliflora" />
                        </div>
                        <h2 className='product-title'>Miere polifloră de vară (fâneață)</h2>
                        <label className='product-price'>35 RON / kg</label>
                        <p className='product-description'>
                            • creșterea imunității organismului datorită conținutului de enzime vii și grăuncioare fine de polen
                            <br />• se recomanda și în tratamentul unor boli de inimă, în caz de insomnie, crampe, constipație, stări febrile
                            <br />• are o puternică acțiune anti-microbiană
                        </p>
                        <div className='out-of-stock-message'>
                            Indisponibil
                        </div>
                    </div>
                    <div className='product-box'>
                        <div className='product-image'>
                            <img src={tei} alt="miere de tei" />
                        </div>
                        <h2 className='product-title'>Miere de tei (cristalizată)</h2>
                        <label className='product-price'>35 RON / kg</label>
                        <p className='product-description'>
                            • întărește sistemul imunitar
                            <br />• ameliorează tusea și iritațiile gâtului
                            <br />• conferă energie
                            <br />• echilibrează digestia
                            <br />• susține funcțiile cerebrale
                            <br />• ajută la tratarea insomniei
                        </p>
                    </div>
                    {/* <div className='product-box'>
                        <div className='product-image'>
                            <img src={poliflora} alt="poliflora" />
                        </div>
                        <h2 className='product-title'>Poliflora</h2>
                        <p className='product-description'>Conține un amestec de nectar de salcâm și pomi fructiferi (cireş, măr, păr, prun)</p>
                        <label className='product-price'>30 RON / kg</label>
                    </div> */}
                    <div className='product-box'>
                        <div className='product-image'>
                            <img src={tinctura_propolis} alt="tinctura de propolis" />
                        </div>
                        <h2 className='product-title'>Tinctură de propolis</h2>
                        <label className='product-price'>15 RON / recipient (20ml)</label>
                        <p className='product-description'>
                            • cel mai puternic antibiotic natural
                            <br />• întărește sistemul imunitar
                            <br />• tratează răcelile și gripele
                            <br />• protejează căile respiratorii
                            <br />• dezinfectează zona bucală
                            <br />• tratează afecțiunile gastro-intestinale
                        </p>
                    </div>
                </div>
            </div>
            <div className='about-section' id="region-2">
                <div className='backgroud-2'>
                    <div className='c-center'>
                        <div className='section-alt-boxes'>
                            <div className='text-container'>
                                <h3 className='text-title'>Stupina familiei</h3>
                                <p className='text-description'>
                                    Ascunsă la poalele dealurilor, în Depresiunea Bradului, stupina familiei, transmisă de-a lungul mai multor generații,
                                    începând de la străbunicul meu, este formată de un număr mare de familii de albine, care produc o cantitate variată de sortimente de miere.
                                    Dintre acestea, unele presupun mutarea familiilor de albine în diferite zone melifere muntoase.
                                    <br /><br />
                                    Produsele noastre sunt aduse în atenția dumneavoastră cu scopul de a valorifica mierea naturală, direct din stupină.
                                </p>
                            </div>
                            <div className='picture-container overlay'>
                                <img src={stupina} alt="apicultor" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='backgroud-3'>
                    <div className='c-center'>
                        <div className='section-alt-boxes'>
                            <div className='picture-container overlay'>
                                <img src={alex_apicultor} alt="stupină" />
                            </div>
                            <div className='text-container'>
                                <h3 className='text-title'>Apicultura - pasiunea mea</h3>
                                <p className='text-description'>
                                    Primele mele amintiri legate de stupină sunt cele în care alergam printre familiile de albine, pe care tatăl meu le îngrijea
                                    zi de zi. Cu trecerea timpului, am început să descopăr treptat plăcerea de a îngriji albinele, ceea ce a devenit
                                    fără doar și poate și pasiunea mea.
                                    <br /><br />
                                    De la un job de 8 ore, stupina este locul în care aleg să evadez și să mă reconectez cu natura.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomePage;